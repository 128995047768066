<script lang="ts">
  import { twMerge } from 'tailwind-merge'
  export let label: string = ''
  export let type: string
  export let name: string
  export let value: any
  export let inputClassName = ''
  export let placeholder = ''
  export let validation = false
  export let errors: string[] = []
  export let suffix: string = null
  export let prefix: string = null
  export let autoCapitalize = 'none'
  export let autoComplete = 'off'
  // ...inputProps

  let className = ''
  export { className as class }

  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()
  function handleOnChange() {
    dispatch('change')
  }

  if (type == null) type = 'text'
</script>

<div class={className}>
  {#if label != ''}
    <div class="flex items-end justify-between text-sm font-normal text-gray-700">
      <span>
        {label}
        <!-- {validation != null && '*'} -->
      </span>
    </div>
  {/if}
  <div class={`relative mt-1 ${prefix && 'flex'}`}>
    {#if prefix}
      <span
        class="inline-flex items-center px-3 text-gray-600 bg-white border border-r-0 shadow-sm rounded-l-md border-grey-md sm:text-sm"
      >
        {prefix}
      </span>
    {/if}
    <input
      {type}
      {value}
      {placeholder}
      {name}
      autocomplete={autoComplete}
      autocapitalize={autoCapitalize}
      on:change|preventDefault={handleOnChange}
      class={twMerge(
        'block w-full text-black transition-colors duration-150 p-2 shadow-sm border-grey-md focus:ring-black focus:border-grey-md rounded',
        prefix && 'rounded-l-none',
        errors &&
          errors.length > 0 &&
          'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
        inputClassName,
      )}
      aria-invalid={!!errors}
      aria-describedby={`error`}
    />
    {#if errors && errors.length > 0}
      <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <img
          src={'/marketing/icons/alerts.svg'}
          class="w-5 h-5 text-red-500"
          height={20}
          width={20}
          loading="lazy"
          alt={`invalid icon`}
          aria-hidden="true"
        />
      </div>
    {/if}
    <slot />
  </div>
  {#if errors}
    <p class="mt-2 text-sm text-red-600" id={`error`}>
      {errors.join(', ')}
    </p>
  {/if}
</div>
